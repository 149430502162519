<template>
  <div>
    <tab-bar-wrapper :columns="columns"></tab-bar-wrapper>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper :editableNodes="editableNodes"
                    :contextMenuItems="contextMenuItems"
                    :query="query"
                    :editedData="editedData"
                    :columns="columns"
                    :componentName="$options.name"
                    :gridMode="gridMode"
                    @ready="ready"
                    @setComments="setComments"
                    >
      </grid-wrapper>
    </div>
    <action-bar-wrapper :commentList="commentList"
                        :buttonList="buttonList"
                        @save="save"
                        @cancel="cancel"
                        @saveComments="saveComments">
    </action-bar-wrapper>
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'

export default {
  name: 'Payable',
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper
  },
  data () {
    return {
      editableNodes: [],
      editedData: [],
      query: {
        module:
          [
            { Data: '12/18/18', TransactionType: 'ST', CV: 'Who is IT We are IT', Debit: 1000000, Credit: '$0' }
          ]
      },

      commentList: {
        'inventory_comment': {
          name: 'inventory_comment',
          label: 'Notes',
          text: '',
          editable: true,
          show: true
        }
      },
      originalComments: [],

      buttonList: {},
      contextMenuItems: (params) => {
        return [
          {
            name: 'Hold'
          }
        ]
      },

      gridMode: 'read',
      gapi: null,
      capi: null,
      gridOptions: null,

      columns: []
    }
  },
  methods: {
    save () {
      // TODO:
    },

    cancel () {
      // TODO:
    },

    setComments () {
      // TODO:
    },

    saveComments () {
      // TODO:
    },

    ready () {
      // TODO:
    }
  }
}
</script>
